export default [
  {
    name: "water",
    type: "texture",
    path: "/textures/sea/water.png",
  },
  {
    name: "tentaclesModel",
    type: "gltfModel",
    path: "/models/tentacles/tentacles.glb",
  },
];
